body {
  //   font-family: "Oxygen", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  //     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //     sans-serif;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: $color-font-text;
  background-color: $color-background-primary;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 2.8rem;
  font-weight: 700;
  margin: 0;
}

h2 {
  font-size: 2.4rem;
  font-weight: 600;
  margin: 0;
}

h3 {
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
}

p {
  font-size: 1.6rem;
  font-weight: 400;
  margin: 0;
}

hr {
  height: 0.05rem;
  margin-bottom: 2rem;
  background-color: $color-border-primary;
  border: none;
}

a {
  color: $color-font-anchor;
  text-decoration: none;
}
