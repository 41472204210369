// app main colors
$color-primary: #007cdc; // Midnight Blue
$color-secondary: #0091ff; // peach

// font colors
$color-font-heading: #285192;
$color-font-text: #08030b;
$color-font-anchor: #285192;
$color-font-dull: #8a8e95;

// button colors
$color-button-primary-text: #285192;
$color-button-primary-background: #e3ebf7;
$color-button-secondary-text: #08030b;
$color-button-secondary-background: #f7f7f7;
$color-button-primary-hover-text: #ffffff;
$color-button-primary-hover-background: #285192;
$color-button-secondary-hover-text: #ffffff;
$color-button-secondary-hover-background: #285192;

// background color
$color-background-primary: #eeeeee;
$color-background-secondary: #e3ebf7;
$color-background-one: #ffffff;

// border color
$color-border-primary: #d5d5d5;

// other color
$color-success: #56bb35;
$color-success-light: #d1f5d2;
$color-error: #bb2d27;
$color-error-light: #f5d1d1;
