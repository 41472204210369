@import "../global/style.scss";

.headercontainer {
  width: 100%;
  padding: 1rem 3rem;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  background-color: $color-background-one;
  position: sticky;
  top: 0;
  z-index: 1000;

  @media screen and (max-width: 640px) {
    padding: 1rem;
  }
}

.logocontainer {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  width: 4rem;
  cursor: pointer;
}

.title {
  font-size: 1.8rem;
  font-weight: 600;
  cursor: pointer;

  @media screen and (max-width: 640px) {
    display: none;
  }
}

.navcontainer {
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  align-items: center;
}

.activenav {
  color: $color-font-anchor;
  font-weight: 600;
}

.nav {
  color: $color-font-text;
}
